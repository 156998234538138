import { hasData } from "@/lib/utils/hasData";
import { openWindow } from "@/lib/utils/openWindow";
import Link from "next/link";
import RotaryLogo from "./rotary-logo";
import SvgJsx from "./svg/svg-jsx";
import { textProcess } from "@/lib/utils/textprocess";

function PopupMenuOptionLink({
  icon,
  iconclass,
  name,
  type,
  url,
  navtype,
  onClick = () => {},
  behavior,
  bol_private = 0,
}) {
  if (behavior == "link")
    return (
      <>
        <button className="group">
          <Link href={url} className="outline-0">
            <PopupMenuOptionLinkContent
              navtype={navtype}
              type={type}
              icon={icon}
              iconclass={iconclass}
              name={name}
              bol_private={bol_private}
            />
          </Link>
        </button>
      </>
    );

  if (behavior == "button")
    return (
      <>
        <button className="group" onClick={onClick}>
          <PopupMenuOptionLinkContent
            navtype={navtype}
            type={type}
            icon={icon}
            iconclass={iconclass}
            name={name}
            bol_private={bol_private}
          />
        </button>
      </>
    );

  if (behavior == "broadcast")
    return (
      <>
        <button
          className="group"
          onClick={() => {
            if (typeof window !== undefined) {
              openWindow(
                `/app/broadcaster`,
                "Web Broadcaster",
                window,
                600,
                600
              );
            }
          }}
        >
          <PopupMenuOptionLinkContentStyle
            navtype={navtype}
            type={type}
            name={name}
            bol_private={bol_private}
            iconjsx={
              <div className={`flex-0 justify-left text-sm ${iconclass} `}>
                <div className="flex-0  mr-1">
                  <RotaryLogo
                    classSize="w-4 h-4 mx-auto block"
                    classAnimate=" animate-spin-slow"
                    bgColor="transparent"
                    centerColor="rgba(31,41,55,1)"
                    pointerColor="#ff0000"
                    holeColor="#111827"
                    dialerColor="#ffffff"
                    size="64"
                  />
                </div>
              </div>
            }
          />
        </button>
      </>
    );
}

function PopupMenuOptionLinkContent({
  navtype,
  type,
  icon,
  iconclass,
  name,
  bol_private,
}) {
  return (
    <>
      <PopupMenuOptionLinkContentStyle
        navtype={navtype}
        type={type}
        name={name}
        bol_private={bol_private}
        iconjsx={
          hasData(icon) && (
            <div className={`flex-0 justify-left text-sm ${iconclass} mr-1 `}>
              <SvgJsx
                type="fill"
                icon={icon}
                className={`h-4 w-4 `}
                title={name}
              />
            </div>
          )
        }
      />
    </>
  );
}

function PopupMenuOptionLinkContentStyle({
  navtype,
  type,
  iconjsx,
  name,
  bol_private,
}) {
  return (
    <>
      <div className="menu-item flex w-full  ">
        <div
          className={`font-bold group cursor-pointer rounded-md flex items-center content-center justify-left w-full px-2 py-2 text-sm leading-5 text-left ring-0
                                  ${
                                    navtype == type
                                      ? ` bg-gray-700 hover:bg-gray-600 hover:text-gray-100 text-gray-200 `
                                      : `bg-gray-900 hover:bg-gray-700 hover:text-gray-100 text-gray-200 `
                                  }
                                  
                                  
                                  `}
        >
          {iconjsx}
          <div className="ml-2 flex-1 ">{textProcess(name)}</div>
          <div className="ml-2 flex-0 ">
            {bol_private == 1 && (
              <SvgJsx
                type="fill"
                icon={"lock-closed-sm"}
                className={`h-4 w-4 `}
                title={textProcess(name)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PopupMenuOptionLink;
