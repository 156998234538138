import { config, playbackRate_array, player_style } from "@/lib/config";
import { countUpBySeconds } from "@/lib/utils/countUp";
import { hasData } from "@/lib/utils/hasData";
import Image from "next/legacy/image";
import { useContext, useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import SvgJsx from "../templateux/svg/svg-jsx";
import { PlayerContext } from "./player-wrapper";
import Slider from "react-input-slider";
import { timerFormat } from "@/lib/utils/timerFormat";
import Link from "next/link";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import { ModalContext } from "../context/modal-wrapper";
import { slugify } from "@/lib/utils/slugify";
import PlayerModal from "./player-modal";
import { ifNull } from "@/lib/utils/ifNull";
//import { useRouter } from 'next/router'

export default function PlayerNavFile({ open, set_open }) {
  const { isConfirmed } = useConfirm();

  const { player_state, set_player_state } = useContext(PlayerContext);
  const { isOpen, set_isOpen } = useContext(ModalContext);
  const [isModalOpen, set_isModalOpen] = useState(false);
  const [clock, set_clock] = useState(`00:00:00`);
  const popupref = useRef(null);
  const playerRef = useRef(null);

  //const [timer, isTargetAchieved] = useTimer({ });

  //const router = useRouter()

  //const [t,set_t] = useState('0')

  //const openMenu = () => {if (ref.current) ref.current.open()};
  const closeMenu = () => {
    if (popupref.current) popupref.current.close();
  };
  //const toggleMenu = () => {if (ref.current) ref.current.toggle()};

  const doSeek = (seekto, x) => {
    player_state?.playerRef?.current?.seekTo(seekto, "seconds");
    set_player_state({
      ...player_state,
      seek: seekto,
      seek_ratio: x / 100,
      seek_slider: x,
      isEnded: seekto >= player_state.duration,
    });

    return;
  };

  const doSeekbyRatio = (x) => {
    const seekto = Math.round((x / 100) * player_state?.duration);
    doSeek(seekto, x);

    return;
  };

  const doSeekbySeconds = (seconds) => {
    const ratio = (seconds * 100) / player_state?.duration;

    doSeek(seconds, ratio);

    return;
  };

  const doPlayback = (speed) => {
    //player_state?.playerRef?.current?.playbackRate(speed);
    set_player_state({ ...player_state, playbackRate: speed });

    return;
  };

  // useEffect(()=>{
  //   if (router?.query) {
  //     let this_t = (hasData(router?.query?.t?.toString()) ? router?.query?.t?.toString() : '0');
  //     set_t(this_t)
  //     set_player_state({
  //         ...player_state
  //         ,seek: this_t
  //     })

  //   //  doSeekbySeconds(router?.query?.t?.toString() >= player_state.duration ? player_state.duration : router?.query?.t?.toString())
  //   }
  // },[
  //   router?.query
  // ])

  const formatMonthDay = (this_date) => {
    let vdate = new Date(this_date);

    return `${vdate?.getMonth() + 1}/${vdate?.getDate()}`;
  };

  const closeModal = () => {
    set_isModalOpen(false);
  };

  return (
    <>
      {hasData(player_state.file) && (
        <div className="flex-1 flex items-center content-center">
          <div
            className="flex-0 ml-2 flex items-center content-center"
            role="button"
          >
            <div className={`flex-0 `}>
              <div className=" flex-0 menu flex items-center content-center relative ">
                <Popup
                  trigger={(isOpen) => (
                    <div
                      className={`flex-0  shrink-0 flex items-center content-center font-bold p-0 pl-0.5 cursor-pointer
                                    rounded-l-full 
                                    
                                   bg-red-600 hover:bg-red-500 border-red-700 text-white
                                    
                                    `}
                      onClick={() => {
                        set_player_state({
                          ...player_state,
                          isPlaying: !player_state.isPlaying,
                        });
                        open !== "player" ? set_open("player") : set_open("");
                      }}
                    >
                      <div className="flex-0 flex items-center content-center  shrink-0">
                        <Image
                          src={
                            hasData(player_state?.avatar_url)
                              ? player_state?.avatar_url
                              : config.avatar.url_missing
                          }
                          alt={player_state?.user_name}
                          className={`h-7 w-7 rounded-full shrink-0 bg-white  object-cover
                                          ${
                                            //LIVE
                                            (player_state?.player_type ==
                                              "live" &&
                                              player_state?.broadcast_status?.toString() ==
                                                "1") ||
                                            //FILE
                                            (player_state?.player_type ==
                                              "file" &&
                                              player_state.isPlaying)
                                              ? "animate-spin-slow"
                                              : ""
                                          }
                                          `}
                          width={24}
                          height={24}
                          quality={100}
                        />
                      </div>
                      <div className="flex-0 ml-1 flex items-center content-center">
                        <div className="flex-0 uppercase ml-0.5 mr-2 w-18 xs:w-24 flex items-center content-center py-1 text-sm">
                          {timerFormat(
                            countUpBySeconds(Math.round(player_state.seek))
                          )}
                        </div>
                        <div
                          className="flex-0 ml-2 flex items-center content-center"
                          role="button"
                        >
                          <SvgJsx
                            type={"fill"}
                            icon={"chevron-down-sm"}
                            className={`h-5 w-5  `}
                            title={"open menu"}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  position="bottom left"
                  on="click"
                  open={open}
                  onOpen={() => set_open("player")}
                  closeOnDocumentClick
                  closeOnEscape
                  mouseLeaveDelay={300}
                  mouseEnterDelay={0}
                  contentStyle={{
                    padding: "0px",
                    border: "none",
                    backgroundColor: "transparent",
                    zIndex: 1999,
                  }}
                  arrow={false}
                  nested
                  ref={popupref}
                >
                  <div
                    className={`menu fixed top-[45px] left-0 sm:left-auto sm:w-64 w-full flex flex-col gap-y-1 rounded-md shadow-xl p-2 mt-0.5
                                    
                                   bg-red-600 text-white
                                      
                                    
                                    `}
                  >
                    <div>
                      {/* INFO */}

                      <div className="">
                        Title:{" "}
                        <Link
                          href={`/${player_state?.user_name?.toLowerCase()}/posts/${slugify(
                            player_state?.post_id,
                            player_state?.title?.toLowerCase()
                          )}`}
                          className="ml-1 underline hover:no-underline outline-0 focus:ring-0 focus:ring-offset-0"
                        >
                          {hasData(player_state?.title)
                            ? player_state?.title
                            : `Untitled ${formatMonthDay(
                                player_state?.stream_start_date
                              )} Show`}
                        </Link>
                      </div>
                      <div className="">
                        Host:
                        <Link
                          href={`/${player_state?.user_name?.toLowerCase()}`}
                          className="ml-1 underline hover:no-underline outline-0 focus:ring-0 focus:ring-offset-0"
                        >
                          {player_state?.user_name}
                        </Link>
                      </div>
                      <div className="">
                        Type:{" "}
                        {{
                          2: "Upload",
                          5: "Showreel",
                        }[player_state?.post_type?.toString()] || "Upload"}
                      </div>

                      <div className="">
                        Duration:{" "}
                        {timerFormat(
                          countUpBySeconds(Math.round(player_state?.duration))
                        )}
                      </div>
                      <div className="">
                        Remaining:{" "}
                        {timerFormat(
                          countUpBySeconds(
                            Math.round(
                              player_state?.duration - player_state?.seek
                            )
                          )
                        )}
                      </div>

                      <div className="mt-1">
                        <button
                          className="border rounded-md border-white px-2 py-1"
                          onClick={() => {
                            set_isModalOpen(true);
                            set_clock(
                              timerFormat(
                                countUpBySeconds(Math.round(player_state?.seek))
                              )
                            );
                          }}
                        >
                          Save timestamp
                        </button>
                      </div>

                      {/* STATUS */}
                      <div>
                        Status:{" "}
                        {player_state?.isMediaLoaded
                          ? "Loaded"
                          : player_state?.isBuffering
                          ? "Buffering"
                          : "Loaded"}
                      </div>
                    </div>

                    <div className="flex items-center content-center">
                      Playback:
                      <select
                        className="bg-red-600 text-white border border-white rounded-md ml-1 text-xs py-0.5 w-32"
                        defaultValue={player_state?.playbackRate}
                        onChange={(e) => doPlayback(e.target.value)}
                      >
                        {playbackRate_array.map((e, index) => {
                          return (
                            <option key={index} value={e.speed}>
                              {e.text}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    {/* SEEK */}
                    <div className="flex items-center content-center">
                      <div className="flex-1 text-sm mt-5 px-2">
                        <Slider
                          axis="x"
                          x={player_state?.seek_slider}
                          xmax={100}
                          //disabled={player_state?.isPlaying}
                          onChange={({ x }) => {
                            doSeekbyRatio(x);
                          }}
                          styles={player_style(player_state?.isPlaying)}
                        />
                      </div>
                    </div>

                    {/* PLAY BUTTONS */}

                    {/* FILE */}

                    <div className="flex items-center content-center text-center my-5">
                      <div className="flex-1">
                        <></>
                      </div>
                      <button
                        className="flex-0 cursor-pointer font-bold rounded-full border hover:border-white h-12 w-12 my-auto mx-auto text-center justify-center text-md flex items-center content-center"
                        tabIndex={0}
                        onClick={() =>
                          doSeekbySeconds(
                            player_state.seek - 15 < 0
                              ? 0
                              : player_state.seek - 15
                          )
                        }
                      >
                        <div>-15</div>
                      </button>

                      {/* PLAY / PAUSE */}
                      {!player_state?.isEnded && (
                        <button
                          className="flex-1 cursor-pointer mx-3 opacity-100 hover:opacity-90"
                          tabIndex={0}
                          onClick={() => {
                            set_player_state({
                              ...player_state,
                              isPlaying: !player_state?.isPlaying,
                            });
                          }}
                          title={player_state?.isPlaying ? "pause" : "play"}
                        >
                          <SvgJsx
                            type={"fill"}
                            icon={
                              player_state?.isPlaying ? "pause-sm" : "play-sm"
                            }
                            className={`h-24 w-24   mx-auto `}
                            title={player_state?.isPlaying ? "pause" : "play"}
                          />
                        </button>
                      )}

                      {/* ENDED */}
                      {player_state?.isEnded && (
                        <button
                          className="flex-1 cursor-pointer mx-5 my-2 opacity-100 hover:opacity-90 "
                          tabIndex={0}
                          onClick={() => {
                            set_player_state({
                              ...player_state,
                              isPlaying: !player_state?.isPlaying,
                              isEnded: false,
                              seek: 0,
                              seek_slider: 0,
                              seek_ratio: 0,
                            });
                          }}
                        >
                          <div className="h-20 w-20 rounded-full mx-auto bg-white flex items-center content-center justify-center">
                            <SvgJsx
                              type={"fill"}
                              icon={"refresh-sm"}
                              className={` h-12 w-12 text-red-500 rounded-full`}
                              title={"restart"}
                            />
                          </div>
                        </button>
                      )}

                      <button
                        className="flex-0 cursor-pointer font-bold rounded-full border hover:border-white h-12 w-12 my-auto mx-auto text-center justify-center text-md flex items-center content-center"
                        tabIndex={0}
                        onClick={() =>
                          doSeekbySeconds(
                            player_state.seek + 30 >= player_state.duration
                              ? player_state.duration
                              : player_state.seek + 30
                          )
                        }
                      >
                        <div>+30</div>
                      </button>
                      <div className="flex-1">
                        <></>
                      </div>
                    </div>

                    {/* VOLUME */}
                    <div className="flex items-center content-center border-t border-red-900 pt-4">
                      <button
                        className={`flex-0 cursor-pointer opacity-100 hover:opacity-75
                                    ${
                                      player_state?.isMuted
                                        ? " animate-pulse"
                                        : ""
                                    }
                          `}
                        onClick={() => {
                          set_player_state({
                            ...player_state,
                            isMuted: !player_state?.isMuted,
                          });
                        }}
                      >
                        <SvgJsx
                          type={"fill"}
                          icon={
                            player_state?.isMuted
                              ? "volume-off-sm"
                              : "volume-up-sm"
                          }
                          className={`h-4 w-4  `}
                          title={player_state?.isMuted ? "muted" : "unmuted"}
                        />
                      </button>
                      <button className="flex-1 ml-2 text-sm">
                        <Slider
                          axis="x"
                          x={player_state?.volume_slider}
                          xmax={100}
                          disabled={player_state?.isMuted}
                          onChange={({ x }) => {
                            set_player_state({
                              ...player_state,
                              volume: x / 100,
                              volume_slider: x,
                            });
                            localStorage.setItem(
                              "volume_slider",
                              x?.toString()
                            );
                          }}
                          styles={{
                            active: {
                              backgroundColor: player_state?.isMuted
                                ? "#555555"
                                : "#0000ff",
                            },
                            track: {
                              backgroundColor: "#111827",
                              display: "flex",
                              height: 15,
                              width: "100%",
                              cursor: "pointer",
                            },
                            thumb: {
                              width: 15,
                              height: 15,
                              backgroundColor: player_state?.isMuted
                                ? "#c0c0c0"
                                : "#ffffff",
                              border: "1px solid #a0a0a0",
                            },
                            disabled: {
                              opacity: 0.5,
                            },
                          }}
                        />
                      </button>
                      <button
                        className="flex-0 ml-3 w-8 text-left text-xs cursor-pointer  opacity-100 hover:opacity-75"
                        onClick={() => {
                          set_player_state({
                            ...player_state,
                            volume: 1,
                            volume_slider: 100,
                            isMuted: false,
                          });
                        }}
                      >
                        {player_state?.isMuted
                          ? "0"
                          : Math.round(player_state?.volume * 100)}
                        %
                      </button>
                    </div>
                  </div>
                </Popup>
              </div>
            </div>

            {/* PLAY/PAUSE */}
            {!player_state?.isEnded && (
              <div
                className={`flex-0 rounded-r-full flex items-center content-center font-bold p-0 h-7
                              cursor-pointer bg-red-600 hover:bg-red-500   text-white
                              
                            `}
                onClick={() => {
                  set_player_state({
                    ...player_state,
                    isPlaying: !player_state.isPlaying,
                    isHide: "0",
                  });
                }}
              >
                <div className="flex-0 flex items-center content-center">
                  <div className="flex-0 p-0 pr-0.5" role="button">
                    <SvgJsx
                      type={"fill"}
                      icon={
                        player_state.isPlaying
                          ? "pause-large-sm"
                          : "play-large-sm"
                      }
                      className={`h-6 w-6 p-1 mt-0.5
                            
                            ${player_state.isPlaying ? "" : "animate-pulse"}
                            `}
                      title={player_state.isPlaying ? "pause" : "play"}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* ENDED */}
            {player_state?.isEnded && (
              <div
                className={`flex-0 border-l rounded-r-full flex items-center content-center font-bold p-1 
                          cursor-pointer 
                            ${
                              player_state.isPlaying ||
                              player_state?.broadcast_status?.toString() == "0"
                                ? ""
                                : "animate-pulse"
                            }
                              bg-red-600 hover:bg-red-500 border-red-700 text-white
                              
                            `}
                onClick={() => {
                  set_player_state({
                    ...player_state,
                    isPlaying: !player_state?.isPlaying,
                    isEnded: false,
                    seek: 0,
                    seek_slider: 0,
                    seek_ratio: 0,
                  });
                }}
              >
                <div className="h-5 w-5 rounded-full mx-auto  flex items-center content-center justify-center">
                  <SvgJsx
                    type={"fill"}
                    icon={"refresh-sm"}
                    className={` h-12 w-12 text-white rounded-full`}
                    title={"restart"}
                  />
                </div>
              </div>
            )}
          </div>

          {/* CLOSE */}
          <div
            className={`flex-0  flex items-center content-center font-bold p-0 cursor-pointer ml-1
                          
                         text-gray-600 hover:text-gray-400`}
            onClick={async () => {
              const confirmed = await isConfirmed(
                "Are you sure you want to close and hide the player?"
              );

              if (confirmed) {
                set_player_state({
                  ...player_state,
                  file: "",
                  title: "",
                  seek: 0,
                  seek_ratio: 0,
                  seek_slider: 0,
                  length: 0,
                  user_id: 0,
                  user_name: "",
                  content_id: 0,
                  content_id_last: 0,
                  post_id: 0,
                  post_id_last: 0,
                  content_table: "",
                  avatar_url: "",
                  isHide: "1",
                  playerRef: playerRef,
                });
                closeMenu();
              }
            }}
          >
            <div className="flex-0 flex items-center content-center">
              <div className="flex-0">
                <SvgJsx
                  type={"fill"}
                  icon={"x-sm"}
                  className={`h-4 w-4  `}
                  title={"close"}
                />
              </div>
            </div>
          </div>

          {/* MESSAGE */}
          <div className="flex-1 ml-2 text-3xs">
            {/* {!player_state?.isMediaLoaded && 
                  <>Loading</>
                  } */}
          </div>

          <PlayerModal
            modal_panel={{ id: "timestamp" }}
            isModalOpen={isModalOpen}
            clock={clock}
            set_clock={set_clock}
            closeModal={closeModal}
            sendmsg={() => {}}
            channel_id={""}
            host_id={player_state?.user_id}
            post_id={ifNull(player_state?.post_id, 0)}
            content_id={player_state?.content_id}
          />
        </div>
      )}
    </>
  );
}
